import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import WeHirePage from "../components/pages/WeHirePage";
import Provider, {Store} from "../components/Store";

const IndexPage = (props) => {
    return (
        <Provider>
            <Store.Consumer>
                {
                    state => {
                        const defaultProps = {...props, ...state, ...state.login, ...state.style}
                        return (
                            <Layout {...defaultProps}>
                                <Header {...defaultProps}/>
                                <div className="app-content u-paddingTop65" style={{minHeight: state.style.height}}>
                                    <WeHirePage {...defaultProps}/>
                                </div>
                                <Footer {...defaultProps}/>
                            </Layout>
                        )
                    }
                }
            </Store.Consumer>
        </Provider>
    )
}

export default IndexPage
