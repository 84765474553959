import React, {useEffect, useState} from 'react';
import TitleBlock from "../../../TitleBlock"
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import ContactForm from "./ContactForm";
import SuccessPage from "./SuccessPage";
import manager from "../../../../images/team_sasha.jpg";
import "./style.styl";


export default function ApplyForm(props) {
    const {open, onClose, isFullscreen, dispatch} = props;
    const [submitted, setSubmitted] = useState(false)



    useEffect(() => {
        if (submitted) {
            setTimeout(() => onClose(), 10000)
        }
    }, [submitted])

    if (submitted) {
        return <SuccessPage show={submitted} onMainPage={() => {setSubmitted(false); onClose()}}/>
    }


    return (
        <Dialog
            fullScreen={isFullscreen}
            open={open}
            maxWidth="lg"
            onClose={onClose}
            >

                <div className="hire-dialog u-relative">
                    <IconButton size="large"
                                onClick={onClose}
                                className="u-absolute u-right10 u-top10"
                                variant="raised">
                        <Close className="u-fontSize36"/>
                    </IconButton>
                    <div className="left u-padding25">
                        <TitleBlock {...props}
                                    title={<span>Application</span>}
                                    description="Let's work together"/>
                        <ContactForm {...props} onSubmit={() => {setSubmitted(true);}}/>
                    </div>
                    <div className="right u-flexCenter u-padding25">
                        <div className="u-sizeFullWidth u-padding25 u-flexColumn u-alignItemsCenter u-justifyContentCenter">
                            <div className="u-opacity50 u-textAlignCenter u-maxWidth200">
                                Your Application is being processed by
                            </div>
                            <div>
                                <Avatar className="u-width100 u-height100 u-marginVertical10" alt="Pavel" src={manager} />
                            </div>

                                <TitleBlock
                                            title="Pavel"
                                            description=""/>
                        </div>
                    </div>
                </div>


        </Dialog>
    );
}
