import React, {useState, useEffect} from 'react';
import TitleBlock from "../../TitleBlock";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AccessTime from "@material-ui/icons/AccessTime";
import LocationOn from "@material-ui/icons/LocationOn";
import {Link as BLink} from "@material-ui/core";
import {Link} from "gatsby";
import ApplyForm from "./ApplyForm"
import Typography from "@material-ui/core/Typography";
import "./style.styl";

export const applications = [
    {
        id: 1,
        position: "Middle Frontend Developer / React.js",
        department: "IT Development",
        location: "Remote",
        schedule: "Part-Time",
        description: "We searching for an Engineer that can develop and maintain our services using React.js with Hooks, Material-UI and SSR."
    },
    {
        id: 2,
        position: "Junior Media Buyer",
        department: "Marketing Department",
        location: "Remote",
        schedule: "Part-Time",
        description: "We looking for a motivated and determined student who has no experience and who wants to excel, deliver & achieve his marketing potential."
    },
    {
        id: 3,
        position: "Junior Designer",
        department: "Marketing Department",
        location: "Remote",
        schedule: "Part-Time",
        description: "We searching for a talented student with no design experience. We will teach you how to make eye-catching creatives using Adobe After Effects and Adobe Photoshop."
    }
]


export default function WeHirePage(props) {
    const {isMobile} = props;
    const [open, setOpen] = useState("")

    return (
        <div id="we-hire-page" className="u-relative container">

            <div className="u-marginBottom50 u-xs-marginBottom40">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/">
                    <BLink color="inherit" className="u-cursorPointer">
                        Home
                    </BLink>
                    </Link>
                    <Typography color="textPrimary">We Hire</Typography>
                </Breadcrumbs>
            </div>

            <div className="main-title">
                <TitleBlock {...props}
                            isAlignCenter
                            title={<div>Current Openings</div>}
                            />
            </div>

            <div className="u-flex u-flexWrap">
                {
                    applications.map((item, i) => {
                        return (
                            <div key={i} className="profile-container">
                                <Profile
                                                position={item.position}
                                                department={item.department}
                                                location={item.location}
                                                schedule={item.schedule}
                                                description={item.description}
                                                id={item.id}
                                                onOpen={setOpen}
                                />
                            </div>
                        )
                    })
                }
            </div>
            <ApplyForm  {...props}
                        open={!!open}
                        selected={open}
                        isFullscreen={isMobile}
                        onClose={() => setOpen("")}/>
        </div>
    );
}



function Profile(props) {
    return (
        <div className="profile u-sizeFullHeight u-flexCenter u-alignItemsCenter u-justifyContentStart u-xs-flexColumn">
            <div className="u-flexCenter u-justifyContentSpaceBetween u-xs-flexColumn">

                <div className="u-sizeFullWidth">
                    <TitleBlock preview={props.department}
                                title={props.position}
                    />
                    <div className="u-flexCenter u-opacity50">
                        <span className="u-flexCenter">
                            <LocationOn className="u-marginRight5"/>
                            {props.location}
                        </span>
                        <span className="u-flexCenter u-marginLeft10">
                            <AccessTime className="u-marginRight5"/>
                            {props.schedule}
                        </span>
                    </div>
                </div>

                <div className="u-sizeFullWidth u-flexCenter u-xs-flexColumn u-xs-marginTop20">
                    <span className="u-opacity80">
                        {props.description}
                    </span>


                    <div className="u-flexCenter u-marginLeft20 u-xs-marginLeft0 u-xs-marginTop20" style={{minWidth: 120}}>
                        <span className="button-blue" onClick={() => props.onOpen(props.id)}>
                            Apply Now
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
