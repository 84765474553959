import React, {useEffect, useState} from 'react';
import plane from "../../../../../images/plane.png";
import "./style.styl";


export default function SuccessPage(props) {
    const {show, onMainPage} = props;
    const [animated, setAnimated] = useState(false);
    const [animatedInitial, setAnimatedInitial] = useState(false);

    useEffect(() => {
        if (!show) {
            return
        }
        const t1 = setTimeout(() => setAnimatedInitial(true), 50);
        const t2 = setTimeout(() => setAnimated(true), 300);
        return () => {
            clearTimeout(t1);
            clearTimeout(t2);
        }
    }, [show]);

    return (
        <div id="lightbox-validation" className={"" + (show ? " show" : "")}>
            <div className={`content ${animated ? "show" : ""}`}>
                <img className="obj-1" src={plane} alt=""/>
                    <div className="container-text-box">
                        <div className="line u-opacity80"><span>YOUR APPLICATION</span></div>
                        <div className="line"><span>HAS BEEN SENT</span></div>
                    </div>
                    <div className="btn u-cursorPointer" onClick={onMainPage}>
                        <p className="anim-btnHover-1">
                            CLOSE
                        </p>
                    </div>
            </div>

        </div>
    );
}
